module.exports = {
  siteName: '点点未来', // Navigation && Site Title
  siteTitle: '点点未来', // Navigation and Site Title
  siteTitleAlt: '点点未来的技术博客', // Alternative Site title for SEO
  siteUrl: 'https://m.1d1d100.com/', // Domain of your site. No trailing slash!
  siteLanguage: 'zh-CN', // Language Tag on <html> element
  siteLogo: '/logos/logo-1024.png', // Used for SEO and manifest
  siteBanner: '/social/banner.jpg',
  siteDescription: '点点未来静态页面群组',

  // Manifest and Progress color
  themeColor: '#090a0b',
  backgroundColor: '#060809',

  // Settings for typography.js
  fontFamily: ['Microsoft YaHei', 'Hiragino Sans GB', 'WenQuanYi Micro Hei', 'sans-serif']
};
