import React from 'react';
import { Helmet } from 'react-helmet';

import config from '../../config/SiteConfig';
const NotFound = () => (
  <main>
    <div>
      <Helmet title={`404页面 | ${config.siteTitle}`} />
      <h1>404页面</h1>
      <p>哎呀，您访问的页面不在服务区。</p>
    </div>
  </main>
);

export default NotFound;
